import restaurant from "./images/patria.jpg";
import img_card from "./images/5.png";
import imgheaderrestaurant from "./images/headerrestaurant.jpg";
import imgheader from "./images/header.webp";
import imgheaderm from "./images/headermb.webp";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/header.jpg";
import imgheadermiinimb from "./images/header.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Denis",
        mireasa: "Dina",
        data: "12 August 2023",
        data_confirmare: "20 iulie 2023",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "dinausurelu0509@gmail.com", 
       tel: "+447859623202",
       phone: "tel:+447859623202",
       viber: "viber://chat?number=%2B447859623202",
       whatsapp: "https://wa.me/+447859623202",
       messenger: "https://www.messenger.com/t/dina.usurelu.7",
       tel1: "+373xxx",
       phone1: "tel:+373xxx",
       viber1: "viber://chat?number=%2B373xxx",
       whatsapp1: "https://wa.me/+373xxx",
       messenger1: "https://www.messenger.com/t/iurii.denis",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Patria",
            data: "12 august 2023, sâmbătă, ora 17:00",
            adress: "Strada Naţionala 19, Ungheni",
            harta: "https://g.page/PalatulSarbatorii?share",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10841.768537204549!2d27.8018441!3d47.2079306!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa5831fa218054710!2zUGFsYXR1bCBTxINyYsSDdG9yaWk!5e0!3m2!1sro!2s!4v1667940052968!5m2!1sro!2s"
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele, vrem să fim inconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
            ps: "P.S.: Am aprecia enorm dacă ați înlocui florile cu o carte sau o sticlă de băutură.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
             
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Dragostea constă în dorința de a da ceea ce este al tău altuia și de a simți fericirea acestuia ca și cum ar fi a ta. \" – Emanuel Swdenborg",
        }
    ],

}

export default data;